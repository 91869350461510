<template>
  <div class="app-drawer">
    <v-navigation-drawer width="320" location="left" temporary v-model="drawer">
      <v-card class="conversations-container" max-width="300" height="84vh">
            <!-- <template> -->
            <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
              <v-tab :value="platforms[0]">Whats app</v-tab>
              <v-tab :value="platforms[1]">Inestagram</v-tab>
            </v-tabs>
            <v-card
            class="mx-auto"
            color="surface-light"
            max-width="400"
          >
            <v-card-text>
              <v-text-field
                :loading="loading"
                append-inner-icon="mdi-magnify"
                density="compact"
                label="Search"
                variant="solo"
                hide-details
                single-line
                @click:append-inner="onClick"
                @keypress.enter.prevent="onClick"
                @update:modelValue="searchValueChanged"
              ></v-text-field>
            </v-card-text>
          </v-card>
            <v-list lines="two">
              <v-list-item
                v-for="item in filterUsers(tab)"
                :key="item.id"
                :title="item.name"
                :subtitle="item.messages.length > 0 ? item.messages[0]['content'] : 'No messages'"
                @click="viewConversationHandler(item.id)"
                :class="[
                  { 'active-conversation': item.id == activeConversation },
                  'conversation-item',
                ]"
              >
                <template v-slot:prepend>
                  <v-avatar color="grey-lighten-1">
                    <v-icon color="white">mdi-account</v-icon>
                  </v-avatar>
                </template>

                <template v-if="item" v-slot:append>
                  <span v-if="tab ? tab !== 'instagram' : false">
                  <v-icon class="new-icon" v-if="item?.messages?.length > 0 && !item?.messages[0]['seen'] && (admin ? item.messages[0]['to'] === admin['id'] : false)" icon="mdi-new-box"></v-icon>

                </span>
        </template>
              </v-list-item>
              <v-list-item v-if="filterUsers(tab).length == 0"
                ><p class="text-center">No Users</p></v-list-item
              >
            </v-list>
            <!-- </template> -->
          </v-card>
     
    </v-navigation-drawer>
  </div>
</template>
<style scoped>
.text-menu-container {
  height: 86vh;
  border-right: 1px solid #efe9e9;
}
.text-menu {
  /* margin: auto 0; */
  /* width: 0; */
  white-space: nowrap;
  height: 10px;
  /* position: absolute; */
  text-align: left;
  transform-origin: bottom;
  margin-top: 300px;
  transform: rotate(270deg);
}
.side-menu {
  position: relative;
}
.toggle-container {
  position: absolute;
  top: 30px;
  right: -10px;
  z-index: 2;
}
.conversations-container {
  overflow-y: auto !important;
  border-right: 1px solid !important;
}
.conversation-item {
  border-bottom: 1px solid #efebeb !important;
}
.active-conversation,
.conversation-item:hover {
  background-color: #e7e7e7 !important;
}
</style>
<script>
import { mapState, mapActions } from 'pinia'
import { useGlobalStore } from '../../stores/global'
import { useUserStore } from '../../stores/user'

export default {
  inject: ["Emitter"],
  data: () => ({
    drawer: false,
    tab: 'whatsapp',
    platforms: ['whatsapp', 'instagram'],
    activeConversation: null,
    showMenuFlag: true,
    isSubRoute: false,
    items: [],
    searchValue: '',
    is_search: false
  }),
  computed: {
    ...mapState( useGlobalStore , [ 'getNotifications']),
    ...mapState(useUserStore, [
      'userList',
      'user',
      'admin',
      'filterUsers',
      'instagramUsers',
      'whatsappUsers',
      'receiveMessage',
      'updateMessage',
      'addMessageToUser',
      'addUser'
    ]),
  },
  async updated() {
    this.isSubRoute = this.$route.params.id !== undefined
    if (!this.isSubRoute) {
      this.activeConversation = null
    }
    // this.getUsersWithPagination()

    console.log('Conversations | updated', {
      isSubRoute: this.isSubRoute,
    })
  },
  async beforeMount() {
    console.log('Conversations | beforeMount')
  },
  async activated() {
    console.log('Conversations | activated')
  },
  async mounted() {
    this.Emitter.on('openUsersDrawer', () => {
      this.drawer = true;
    })
    document.title = 'Conversations'
    await this.getAdmin()
    this.getUsersWithPagination()
    //const baseUrl = process.env.VUE_APP_WS_BASE 
    const baseUrl = process.env.VUE_APP_BACK_END_BASE_URL

    // if(this.userList){
    //   console.log("Conversations | mounted", 'users already loaded')
    // }else{
    //   this.getUsers();
    // }

    // eslint-disable-next-line no-undef
    const socket = io.connect(`${baseUrl}`, {
    transports: ['websocket'], // specify WebSocket as the only transport method
});

    socket.on('status', async (data) => {
      console.log({ data })
      if (data.status === 'failed' || data.status === 'fail') {
        // this.$store.commit("SET_NOTIFICATION", {
        //   display: true,
        //   text: data.statusMsg,
        //   class: "error",
        // });
        this.setAlertMessage( {
          display: true,
          text: data.statusMsg,
          class: "error",
        })
      }

      if (this.user) {
        if (data.msg.to === this.user['id']) {
          this.user['messages'].forEach((msg) => {
            if (msg.client_id === data.msg.client_id) {
              msg.status = data.status
              msg.wa_msg_id = data.msg.wa_msg_id
              msg.statusTitle = data.statusMsg
            }
          })
        }
      }
    })

    // socket.on('chat', async (data) => {
    //   console.log("chat", {data})
    //   const receivedData = data.fromUser[0];
    //   const palatform = receivedData.platform;

    //   if(data.fromUser[0]['isNewUser']) { 
    //     this.addUser(receivedData, palatform);
    //   } else {
    //     if(this.user){
    //       if(this.user['id'] === receivedData.id){
    //         this.updateMessage(receivedData.messages[0]._id, { seen: true });
    //         this.addMessageToUser(receivedData);
    //       } else {
    //           // this.receiveMessage(receivedData.messages[0], palatform);
    //       }
    //     }else{
    //       // this.receiveMessage(receivedData.messages[0], palatform);
    //     }
    //     this.receiveMessage(receivedData, palatform);

    //   }
    // })
    console.log('Conversations | mounted', {
      id: this.$route.params.id,
      userList: this.whatsappUsers,
    })

    this.isSubRoute = this.$route.params.id !== undefined

    if (this.isSubRoute) {
      this.activeConversation = this.$route.params.id
    }
    this.items = this.tab === 'whatsapp' ? this.whatsappUsers : this.instagramUsers
  },
  methods: {
    ...mapActions(useUserStore, ['getUsers', 'getUsersWithPagination', 'getAdmin', 'setIsUserUpdated', 'search']),
    ...mapActions(useGlobalStore, ['setAlertMessage']),

    viewConversationHandler: function (id) {
      console.log({
        id,
      })
      this.activeConversation = id
      this.setIsUserUpdated()
      this.$router.push({ name: 'conversation', params: { id }, query: { platform: this.tab } })
    },
    showSideMenuHandler: function () {
      console.log('showSideMenuHandler', this.showMenuFlag)
      this.showMenuFlag = !this.showMenuFlag
    },
    // openUsersDrawer() {
    //   this.Emitter.emit('openUsersDrawer')
    // },
    onClick: async function () {
      if(this.searchValue !== ''){
        this.is_search = true;
        await this.search(this.searchValue);
      }else{
        if( this.is_search){
          await this.search(this.searchValue);
        }
        this.is_search = false;
      }
    },
    searchValueChanged(value){
      this.searchValue = value;
    }
  },
};
</script>
